const AboutUs = () => {
    const contentText = `חברת טריו פעילה במסגרת שירותי ענן ומשווקת השירותים של חברת מיקרוסופט, בדגש על שירותי Office 365 ושירותי Azure.
    החברה מספקת שירותי ענן בחיוב חודשי במטבע מקומי עם התחייבות שנתית ומעטפת שירותי ענן משלימים.
    כל השירותים ניתנים מתוך הענן המוביל בישראל עם תמיכה מקומית בעברית וזמינות ללקוחות החברה 24/7.
    נציגי טריו דואגים לליווי הלקוח בכל שלב בתהליך ההקמה או המיגרציה של השירותים.
    כל היתרונות של ספק בינלאומי עם כל הערכים המוספים של ספק מקומי.`;

    return <div className="about_us">
        <h2>אודות חברת טריו</h2>
        <div className="about_text">{contentText}</div>
    </div>
};

export default AboutUs;