import React from 'react';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import MailToBtn from './components/MailToBtn';
import bg_img from './media/365_pic.jpg';
import logo_src from './media/trio_logo.png';

function App() {
  const banner_title = 'Office 365';
  const banner_text = 'הגדילו את הפעילות העסקית שלכם עם כל כלי Office המוכרים לצד יכולות עבודה בענן עם שירותי Office online webapps';
  const list_items = [
    'חיוב חודשי ולא שנתי',
    'חיוב מקומי בשקלים',
    'תמיכה מקומית בלעדית',
    'מוקד שירות בעברית 7×24',
    'ליווי הלקוח בכל תהליך המעבר',
    'מעטפת שירותי ענן משלימים',
    'הענן הישראלי המוביל',
    'שירות ה- Office אולטימטיבי',
    'שירות הדואר הפופולארי בעולם',
    'מונע וחוסך שדרוגים',
    'שקט מתחזוקה ותקלות',
    'עלות שירות נמוך מרכישה',
    'תמיכה מקומית בשירות גלובלי'
  ];

  const bannerStyling = {
    backgroundImage: `url(${bg_img})`
  }

  return (
    <>
      <div className='heading'>
        <MailToBtn>צור קשר</MailToBtn>
        <img src={logo_src} alt="Trio Computing Systems ltd" />
      </div>
      <div className='banner' style={bannerStyling}>
        <div className='banner_centered'>
          <h2 className='banner_title'>{banner_title}</h2>
          <p className='banner_text'>{banner_text}</p>
        </div>
      </div>
      <div className='content'>
        <p className='content_title'>השילוב המנצח של יתרונות ענן מקומי בשיתוף עם שירותי 365</p>
        <ul className='content_list'>
          {list_items.map(item => {
            return <li key={item}>{item}</li>
          })}
        </ul>
      </div>
      <AboutUs />
      <Footer />
    </>
  );
}

export default App;
