const Footer = () => {
    const info_list = [
        'משרדי החברה נמצאים ברחוב המדרון 11 הרצליה',
        'תחום הארגון: מחשוב, מחשוב ענן ושירותי מומחה',
        'כתובת דואר אלקטרוני: admin@trio-ccc.co.il',
        'מספר טלפון: 054-9201212',
        'מספר טלפון: 077-2761047'
    ]

    const info_list_eng = [
        'Company offices are located in HaMidron Street 11, Herzliya, Israel',
        'Organization specialty: Computing, Cloud computing and professional services',
        'Email: admin@trio-ccc.co.il',
        'Phone number: +972549201212',
        'Phone number: +972772761047'
    ]

    return <div className="footer">
        <ul>
            {info_list.map(item => {
                return <li key={item}>{item}</li>
            })}
        </ul>
        <ul className="eng_info_footer">
            {info_list_eng.map(item => {
                return <li key={item}>{item}</li>
            })}
        </ul>
    </div>
};

export default Footer;